import { TextField } from '@mui/material';

function PhoneInput(props) {
  const { value, onChange, label, required, error, helperText, name } = props;

  const handleChange = (event) => {
    const input = event.target.value;
    if (input === '' || input.match(/^[\d\s+]*$/)) {
      onChange(event);
    }
  };

  return (
    <TextField
      value={value || ''}
      onChange={handleChange}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      name={name}
      variant="outlined"
      fullWidth
      size="small"
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-root': {
          height: '56px',
        },
        '& .MuiOutlinedInput-input': {
          height: '24px',
        },
      }}
    />
  );
}

export default PhoneInput;
